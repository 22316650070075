import React from 'react'
import { Link } from "gatsby"
import { rhythm } from "../utils/typography"
import Colors from '../constants/Colors';
import Variables from '../constants/Variables';

const Blogcard = (props) => {
    return (
        <div style={card} className="card elevation">
            <div key={props.node.fields.slug}>
            <h3 className="blog-card-header" style={{ cardTitle }}>
                <Link className="blog-card-link" style={{ boxShadow: `none` }} to={props.node.fields.slug}>
                  {props.title}
                </Link>
            </h3>
            <p className="blog-card-date" style={cardSubtitle}>{props.node.frontmatter.date}</p>
            <p
                dangerouslySetInnerHTML={{
                __html: props.node.frontmatter.description || props.node.excerpt,
                }}
            />
            </div>
        </div>
      )
}

const card = {
  backgroundColor: Colors.white,
  padding: 25,
  marginBottom: 20,
  borderRadius: 15,
  border: "1px solid #E8EAED",
};

const cardTitle = {
  color: Colors.almostBlack,
  fontWeight: Variables.fontWeightHeadline,
  fontSize: 20,
  lineHeight: 1,
  marginBottom: rhythm(1 / 8),
};

const cardSubtitle = {
  color: Colors.grey,
};

export default Blogcard;

